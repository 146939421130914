import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import {
  faIdCard,
  faCrosshairs,
  faBook,
  faUsers,
  faStethoscope,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WhyPatronscan from '../../../../theme/src/components/sections/why-patronscan';
import SEO from '../../../../theme/src/components/seo';
import './why-patronscan.css';
import './advantages.css';

interface AdvantagePageProps {}

const AdvantagePage: React.FunctionComponent<AdvantagePageProps> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "advantages-countertop-4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const advantages = [
    {
      icon: faIdCard,
      heading: 'Fake ID Detection',
      text:
        'Our first-class fake ID detection algorithm can authenticate and catch most fake IDs within seconds. We use patent pending algorithms that go beyond the simple barcode scanners. With sophisticated fake IDs coming from around the world, we are always on the forefront of the authentication technology.',
    },
    {
      icon: faCrosshairs,
      heading: 'Accuracy',
      text:
        'Our software uses a combination of technologies such as artificial intelligence, character recognition, barcodes, rfids, microprint, etc. to ensure that you will have the most accurate ID scanner in the market.',
    },
    {
      icon: faBook,
      heading: 'Extensive Library',
      text:
        'We use our own proprietary ID scanning engine (patent pending). With over 4500 documents and counting built into our document library, we can read a multitude of IDs from around the world, including non-standard IDs and passports. Whenever we encounter a new ID, we add it to our library and release it within 2 business days.',
    },
    {
      icon: faUsers,
      heading: 'Training',
      text:
        'We are always improving and releasing new features. Whether you have new staff or we release new modules, we will always be available to give you free training with one of our support specialists.',
    },
    {
      icon: faStethoscope,
      heading: 'Ongoing Diagnostics',
      text:
        'Our team of engineers are always looking for ways to make our solution better and faster. Some of our best features come from our customers feedback and our ongoing diagnostics. With us, you will be constantly updated to the best innovation.',
    },
    {
      icon: faQuestionCircle,
      heading: '24/7 Support',
      text:
        'With Patronscan you will always be looked after. We have unlimited support with a real human, always available to help you anytime you need.',
    },
  ];

  return (
    <div className="bg-white">
      <SEO title="Advantages - Patronscan" />
      <section id="advantage-header" className="pt-12">
        <div className="flex flex-row-reverse xl:flex-row items-center justify-between flex-wrap py-16 advantages-hero-container">
          <div className="xl:w-full xl:p-12 xl:max-w-2xl advantages-description-container">
            <h2 className="mb-4 text-blue font-heading leading-none text-5xl thick-heading">
              The Patronscan Advantage
            </h2>
            <p className="text-grey-darker my-4">
              Patronscan is a privately held company built from the ground up by continuous
              innovation and hyper customer care. In our early stages we tried other ID scanners and
              found that they didn't do a great job of reading and authenticating IDs. As part of
              our progress we developed our own proprietary technology that has become the leader in
              ID authentication worldwide.
            </p>
            <p className="text-grey-darker my-4">
              Our customers use Patronscan in multiple industries to create safe environments and
              trustworthy relationships. As part of our hyper customer focus, we build custom
              solutions to specific needs as well as implement the best feedback for our off the
              shelf products. When you choose Patronscan, you become part of our family, and as such
              we are committed to you and your success.
            </p>
          </div>
          <div className="w-full xl:max-w-2xl xl:py-16 advantages-hero-image">
            <Img fluid={data.image.childImageSharp.fluid} />
          </div>
        </div>
      </section>
      <section id="advantages" className="advantages-list py-16">
        <div className="mx-auto max-w-container">
          <h2 className="mb-8 text-blue font-heading leading-none text-5xl thick-heading">
            Our Commitment to You
          </h2>
          <div className="flex flex-row flex-wrap">
            {advantages.map((advantage: any) => {
              return (
                <div className="p-4 w-full md:w-1/2 lg:w-1/3 flex flex-row items-stretch">
                  <div className="shadow-md bg-white">
                    <div
                      key={advantage.heading}
                      className="p-4 md:p-8  text-center max-w-sm inline-flex flex-col justify-start items-center"
                    >
                      <FontAwesomeIcon icon={advantage.icon} color="#015776" size="4x" />
                      <h3 className="text-2xl my-8 font-heading leading-tight">
                        {advantage.heading}
                      </h3>
                      <p className="text-sm text-grey-paragraph">{advantage.text}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
        <WhyPatronscan />
    </div>
  );
};

export default AdvantagePage;
